<style lang="scss" scoped>
.tolttip-message {
  display: none !important;
}

.mx-input {
  height: 3em !important;
}

body {
  background: red !important;
}
 
.mx-datepicker-range > input {
  height: 3em !important;
}

#header {
  position: relative !important;
}
 
.btn-comercio {
  text-decoration: none;
  padding: 7.5px 10px;
  font-weight: 600;
  font-size: 1em;
  color: #ffffff;
  background-color: rgb(0, 38, 230);
  border-radius: 6px;
  width: 100%;
  border: 2px solid #0016b0;
}
.btn-comercio:hover {
  background-color: rgb(64, 204, 255);
  border: 2px solid rgb(64, 204, 255);
}

.title__login {
  color: #21458d;
  font-size: 30px;
  font-weight: 600;
}

.btn__ingresar {
  background: #4b92fc !important;
  border-color: #4b92fc !important;
  height: 45px;
  font-weight: 500 !important;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.container__login {
  margin-top: 2%;
  // height: 85vh;
}
.label__input {
  font-size: 17px;
  font-weight: 500px;
  color: #000;
}

.p__input {
  margin-bottom: 0px;
  position: relative;
  top: -10px;
  font-size: 13px;
  color: #4d4d4d;
}

.page-not-available {
  background-color: #4b92fc;
  height: 100vh;
  width: 100%;
  color: #fff;
  font-size: 2em;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.page-not-available > p {
  text-align: center;
}
.page-not-available > p:nth-child(1) {
  font-size: 2.5em;
  font-weight: bold;
  text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.2);
}

.page-not-available > p:nth-child(2) {
  color: rgb(34, 59, 102);
}
</style>

<template>
  <div  v-if="flag" >
    <header id="header">
      <a href="#hero-section">
        <img src="../../assets/img/logo_negro.png" id="header-img" />
      </a>
      <nav>
        <ul class="mb-0">
          <router-link class="nav-link btn__landing" to="/">
            <img src="../../assets/img/arrow.png" style="width: 45px" />
          </router-link>
        </ul>
      </nav>
    </header>

    <div class="d-flex align-items-center container__login">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        :is-full-page="fullPage"
      >
      </loading>
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <CCard class="mx-md-4 mb-0">
              <CCardBody class="p-4">
                <CForm>
                  <h1 class="title__login" style="color: black !important">
                    Actualizar correo digital
                  </h1>

                  <hr style="border-style: solid; border-width: 1px" />

                  <!-- //TODO: Tipo documento Y N° Documento -->
                  <CRow>
                    <CCol class="mb-sm-3" md="6" sm="12">
                      <label class="label__input">Tipo documento</label>
                      <p
                        v-if="isTipoDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo tipo de documento está vacio
                      </p>
                      <select
                        v-model="tipoDocumento"
                        class="form-control"
                        style="color: black !important"
                        @change="changeTipoDocumento($event)"
                      >
                        <option value="">--Seleccionar--</option>
                        <option value="DNI">DNI</option>
                        <option value="RUC">RUC</option>
                        <option value="Carnet de extranjeria">
                          Carnet de extranjeria
                        </option>
                        <option value="Pasaporte">Pasaporte</option>
                        <option value="CDI">CDI</option>
                      </select>
                    </CCol>
                    <!--//TODO: Numero documento-->
                    <CCol md="6" sm="12">
                      <label class="label__input">N° Documento </label>
                      <p
                        v-if="isNumeroDocumento"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*) El campo Numero documento esta vacio
                      </p>
                      <CInput
                        @keypress="validateNumber($event)"
                        @keyup="validateLength($event)"
                        v-model="numeroDocumento"
                      ></CInput>
                    </CCol>
                  </CRow>
                  <!-- TODO: boton confirmar numero de documneto -->
                  <CRow>
                    <CCol class="mb-3" md="12" sm="12">
                      <button
                        type="button"
                        class="btn-comercio btn__ingresar"
                        @click="verificar($event)"
                      >
                        Verificar
                      </button>
                    </CCol>
                  </CRow>
                  <!--//TODO: Suscripcion Contratada -->
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Suscripción Contratada</label>
                      <p
                        v-if="isSuscripcion"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo suscripción está vacio
                      </p>
                      <select
                        disabled
                        @change="consulta($event)"
                        id="inputSuscripcion"
                        v-model="suscripcion"
                        class="form-control mb-3"
                        style="color: black !important"
                      >
                      <option value="">--Seleccionar--</option>
                      <option v-for="(suscrip,index) in listaSuscripciones" 
                      :key="index+1"
                      :value="index+1">
                          {{index + 1}}. {{suscrip.product}}--{{suscrip.periodicity}}--{{suscrip.portal}}
                      </option>
                      </select>
                    </CCol>
                  </CRow>

                 <!-- //TODO: correo -->
                  <CRow>
                    <CCol>
                      <label class="label__input">Actual correo electrónico</label>
                      <p
                        v-if="isCorreo"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo correo está vacio
                      </p>
                      <CInput
                        disabled
                        id="inputCorreo"
                        placeholder="ejemplo@gmail.com..."
                        type="email"
                        v-model="correo"
                        style="color: black !important"
                      >
                      </CInput>
                    </CCol>
                  </CRow>


                  <!-- //TODO: Nuevo correo -->
                  <CRow>
                    <CCol>
                      <label class="label__input">Nuevo correo electrónico </label>
                      <p
                        v-if="isCorreoNew"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo correo está vacio
                      </p>
                      <CInput
                        disabled
                        id="inputCorreoNew"
                        placeholder="ejemplo@gmail.com..."
                        type="email"
                        v-model="correoNew"
                        style="color: black !important"
                      >
                      </CInput>
                    </CCol>
                  </CRow>
                   <!-- TODO: Numero Telefonico-->
                  <CRow>
                    <CCol md="12" sm="12">
                      <label class="label__input">Número telefónico</label>
                      <p
                        v-if="isNumeroTelefonico"
                        style="
                          color: red;
                          font-size: 0.8em;
                          margin: 0 0 5px 0;
                          padding: 0;
                        "
                      >
                        (*)El campo número telefónico está vacio
                      </p>
                      <CInput
                        disabled
                        id="inputTelefono"
                        v-model="numeroTelefonico"
                        @keypress="validateNumber($event)"
                        maxlength="9"
                        style="color: black !important"
                      >
                      </CInput>
                    </CCol>
                  </CRow>

                 <!-- //TODO: Observacion -->
                <CRow>
                    <CCol>
                    <label class="label__input">Observación</label>
                    <p v-if="isObservacion" style="color:red;font-size:0.8em;margin:0 0 5px 0;padding:0;">(*)El campo observacion está vacio</p>
                    <CTextarea   
                    id="inputObservacion"
                    disabled             
                        v-model="observacion"
                         style="color:black !important;">
                    </CTextarea>
                    </CCol>
                </CRow>


                  <!-- //TODO: Enviar -->
                  <button
                    id="boton"
                    disabled
                    type="button"
                    class="btn-comercio btn__ingresar"
                    @click="registrarFreshdesk()"
                  >
                    Enviar
                  </button>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
 <div v-else class="page-not-available">
    <p>Oooops Página no disponible</p>
    <p>Recuerda que los formularios de atención de <b>"Cambio de dirección"</b>,<b>"Incidencia de reparto"</b> y <b>"Otras consultas y solicitudes"</b> estan disponibles en el siguiente horario: <b>7:00 pm a 6:59 am</b></p>
  </div> 
</template>

<script>
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Table from "../base/Table.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ProgressBars from "../base/ProgressBars.vue";

export default {
  name: "ActualizarCorreoDigital",
  data() {
    return {
      /* FIXME: valores nesesarios */
    documento: null,
    isLoading: false,
    fullPage: true,

    isTipoDocumento: false,
    isNumeroDocumento: false,
    isSuscripcion: false,
    isCorreo:false,
    isCorreoNew: false,
    isNumeroTelefonico:false,
    isObservacion:false,

    tipoDocumento: "",
    numeroDocumento: "",
    suscripcion: "",
    listaSuscripciones:[],
    correo:"",
    correoNew: "",
    numeroTelefonico:"",
    observacion:"",
    product:"",
    id:"",

      flag: false,
    };
  },

  components: {
    Loading,
    Table,
    DatePicker,
    ProgressBars,
  },
  methods: {
    /* TODO: Validaciones */
    async verificar() {
      let vm = this;
      if (vm.tipoDocumento == "" && vm.numeroDocumento == "") {
        vm.isTipoDocumento = true;
        vm.isNumeroDocumento = true;
      } else{
          let data ={
          document_number:vm.numeroDocumento,
          document_type :vm.tipoDocumento,
          channel:7,
          optionQuery:4,
          queryType:1,
          optionProcess :1,
          token:""
        }
        axios.post(`${process.env.VUE_APP_ROOT_API}/listSuscripChatbot`,data)
          .then(function(res){
            vm.isLoading = false;
            if(res.data.data.code==1){
              vm.$fire({
              title: "¡Listo!",
              text:"Sus datos fueron verificados con éxito",
              type: "success",
              timer: 4000,
              }).then((r)=>{
              vm.listaSuscripciones= res.data.data.landing;
              vm.isTipoDocumento = false;
              vm.isNumeroDocumento = false;
                document.getElementById('boton').disabled = false;
              document.getElementById('inputSuscripcion').disabled = false;
              document.getElementById('inputCorreo').disabled = true;
              document.getElementById('inputCorreoNew').disabled = false;
               document.getElementById('inputObservacion').disabled = false;
               document.getElementById('inputTelefono').disabled = false;

              });
            }else{
              vm.$fire({
              title: "¡Lo sentimos!",
              text: "Usted no cuenta con suscripciones registradas",
              type: "error",
              timer: 10000,
            }).then((r)=>{
              console.log("dismin", r);
                 document.getElementById('boton').disabled = true;
              document.getElementById('inputSuscripcion').disabled = true;;
              document.getElementById('inputCorreo').disabled = true;
              document.getElementById('inputCorreoNew').disabled = true;
               document.getElementById('inputObservacion').disabled = true;
               document.getElementById('inputTelefono').disabled = true;
            })
            }
          }).catch((error)=>console.log(error))
      }

       
    },
    async consulta(){
        let vm = this
      
            let data1={
                        position_id:String(vm.suscripcion),
                        consulta:"4",
                        document_type:String(vm.tipoDocumento),
                        document_number:String(vm.numeroDocumento),
                        platformOrigin:"Chatbot"
                    }
            await axios.post(`${process.env.VUE_APP_ROOT_API}/consultaPaywall`,data1).then((res)=>{
                vm.isLoading = false;
               vm.correo = res.data.data.landing.email;
               vm.product = res.data.data.landing.product;
                vm.id = res.data.data.landing.id;
            })
    },
    /* TODO:validar tipo documento */
    changeTipoDocumento(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumento = id;
      vm.numeroDocumento = "";
    },
    /* TODO:validar tipo documento new */
    changeTipoDocumentoNew(event) {
      let vm = this;
      let id = event.target.value;
      vm.tipoDocumentoNew = id;
      vm.numeroDocumentoNew = "";
    },
    /* TODO:validar numero */
    validateNumber(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    /* TODO:validar tamaño del numero */
    validateLength(event) {
      let vm = this;
      let val = event.target.value;
      let len = 20;
      if (vm.tipoDocumento == "DNI") len = 8;
      else if (vm.tipoDocumento == "RUC") len = 11;
      if (val.length > len) {
        vm.numeroDocumento = event.target.value.substring(0, len);
      }
    },
    validateNumberAndPoint(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9.]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateNumberAndLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[0-9a-zA-z]$");
      if (!myRex.test(val)) event.preventDefault();
    },
    validateOnlyLetter(event) {
      let val = event.key;
      let myRex = new RegExp("^[a-zA-z ]$");
      if (!myRex.test(val)) event.preventDefault();
    },

     async  registrarFreshdesk() {
    let vm = this;

    vm.isTipoDocumento=false;
    vm.isNumeroDocumento=false;
    vm.isSuscripcion=false;
    vm.isCorreo=false;
    vm.isCorreoNew=false;
    vm.isNumeroTelefonico=false;
    vm.isObservacion=false;
    

      if (vm.tipoDocumento == "") {
        vm.isTipoDocumento = true;
      }

      if (vm.numeroDocumento == "") {
        vm.isNumeroDocumento = true;
      } 
      if (vm.suscripcion == "") {
        vm.isSuscripcion = true;
      }
      if (vm.correo == "") {
        vm.isCorreo = true;
      }

      if (vm.correoNew == "") {
        vm.isCorreoNew = true;
      }
      if(vm.numeroTelefonico == ""){
        vm.isNumeroTelefonico = true;
      }
      if (vm.observacion == "") {
        vm.isObservacion = true;
      }

      if (
     

        vm.isTipoDocumento == false &&
        vm.isNumeroDocumento == false &&
        vm.isSuscripcion == false &&
        vm.isCorreo==false&&
        vm.isCorreoNew==false&&
        vm.isNumeroTelefonico==false&&
        vm.isObservacion==false
      ) {
        if (
          vm.numeroTelefonico.length != 7 &&
          vm.numeroTelefonico.length != 9
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe contener 7 u 9 números.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          vm.numeroTelefonico.length == 9 &&
          vm.numeroTelefonico.charAt(0) != "9"
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "El campo número telefónico debe comenzar con el dígito 9.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else if (
          !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
            vm.correo
          )
        ) {
          vm.$fire({
            title: "Seguridad",
            text: "La dirección de correo es incorrecta.",
            type: "info",
            timer: 4000,
          }).then((r) => {
            console.log("dismin", r);
          });
        } else {

          let data = {
            tipoDocumento: vm.tipoDocumento,
            numeroDocumento: vm.numeroDocumento,
            suscripcion: vm.suscripcion,
            product:vm.product,
            correo: vm.correo,
            correoNew:vm.correoNew,
            numeroTelefonico:vm.numeroTelefonico,
            observacion:vm.observacion,
            id: vm.id,
            tipo: 11,
          };
 
          //console.log("Data =>", data);

         await axios

            .post(`${process.env.VUE_APP_ROOT_API}/actualizar-correo-digital`, data)
            .then(function (res) {
              vm.isLoading = false;
                  if (res.data.data.code== 1) {
                      vm.$fire({
                              title: `Se actualizó su correo electronico`,
                              html:`${res.data.data.message} <br/><br/> Gracias por escribirnos.`,
                              type: "success",
                              timer: 6000,
                                }).then((r) => {});
                                
                                vm.tipoDocumento= "";
                                vm.numeroDocumento= "";
                                vm.suscripcion= "";
                                vm.numeroTelefonico="";
                                vm.product="";
                                vm.correo= "";
                                vm.correoNew="";
                                vm.observacion="";
                                vm.id="";
              } else {
                    vm.$fire({
                            title: "Seguridad",
                            html: res.data.data.message,
                            type: "info",
                            timer: 40000,
                          }).then((r) => { console.log("dismin", r);
                           });
              }

            })
            .catch((error) => {
                          vm.isLoading = false;
                          vm.$fire({
                          title: "Error",
                          text: "Ocurrio un error en el servidor",
                          type: "error",
                          timer: 6000,
                          }).then((r) => {});
                    });
        }


      }
    },

    redirectLanding() {
      let vm = this;
      vm.$router.push("/");
    },
  },

  mounted() {
    let vm = this;
    axios.interceptors.request.use(
      (config) => {
        vm.isLoading = true;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    $("#pgb-container").hide();

    const date = new Date();
    const hour = date.getHours();
    
    if((hour >= 0 && hour < 7) ||  hour >= 19 ){
      vm.flag = true;
    }

  },
};
</script>