import { render, staticRenderFns } from "./ActualizarCorreoDigital.vue?vue&type=template&id=4d36cd10&scoped=true&"
import script from "./ActualizarCorreoDigital.vue?vue&type=script&lang=js&"
export * from "./ActualizarCorreoDigital.vue?vue&type=script&lang=js&"
import style0 from "./ActualizarCorreoDigital.vue?vue&type=style&index=0&id=4d36cd10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d36cd10",
  null
  
)

export default component.exports